import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  PieChart, Pie, Cell, Tooltip, ResponsiveContainer,
} from 'recharts';
import {
  getCryptoAssetsDetail,
  updateTransactionState,
} from '../../../redux/transactions/transactionActions';
import FullPageTransparentLoader from '../../FullPageTransparentLoader/fullPageTransparentLoader';
// import { CanvasGraph } from './CanvasGraph';
import { GetCoinImg } from '../../../helpers/getCoinImg';
import { getCurrencyRates } from '../../../redux/currencyRate/currencyRateActions';

const COLORS = ['#8345e6b2', '#ec50a3'];
const MIN_SLICE_PERCENTAGE = 15; // Minimum display size for any slice

const renderCustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div style={{
        backgroundColor: '#0f1323', padding: '10px', borderRadius: '6px', color: '#fff',
      }}
      >
        <p>{`${payload[0].name}: ${payload[0].payload.originalValue}`}</p>
        {' '}
        {/* Display original value */}
      </div>
    );
  }
  return null;
};

function CryptoPieChart({ item }) {
  const totalDeposits = item?.totalDeposits || 0;
  const totalWithdrawals = item?.totalWithdrawals || 0;
  const total = totalDeposits + totalWithdrawals;

  // Calculate the percentage for each data point
  const depositsPercentage = total ? (totalDeposits / total) * 100 : 0;
  const withdrawalsPercentage = total ? (totalWithdrawals / total) * 100 : 0;

  // Enforce a minimum percentage for display, but keep the original values for tooltip
  const adjustedDeposits = depositsPercentage < MIN_SLICE_PERCENTAGE ? MIN_SLICE_PERCENTAGE : depositsPercentage;
  const adjustedWithdrawals = withdrawalsPercentage < MIN_SLICE_PERCENTAGE ? MIN_SLICE_PERCENTAGE : withdrawalsPercentage;

  // Adjust the data for rendering, while keeping original values for tooltips
  const adjustedTotal = adjustedDeposits + adjustedWithdrawals;

  const data = [
    {
      name: `Total Deposits ${item?.currency}`,
      value: (adjustedDeposits / adjustedTotal) * 100, // Adjusted value for rendering
      originalValue: totalDeposits, // Keep the original value for the tooltip
    },
    {
      name: `Total Withdrawals ${item?.currency}`,
      value: (adjustedWithdrawals / adjustedTotal) * 100, // Adjusted value for rendering
      originalValue: totalWithdrawals, // Keep the original value for the tooltip
    },
  ];

  return (
    <ResponsiveContainer width="100%" height={200}>
      <PieChart>
        <Pie
          data={data}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          innerRadius={40}
          outerRadius={80}
          fill="#8884d8"
          stroke="none"
        >
          {data.map((entry, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip content={renderCustomTooltip} />
      </PieChart>
    </ResponsiveContainer>
  );
}

function CryptoCharts({ filter = {} }) {
  const dispatch = useDispatch();

  const [loader, setLoader] = useState(false);
  const transactionAssets = useSelector((state) => state?.transaction?.assetDetails);
  const fetchedAssetDetails = useSelector((state) => state?.transaction?.fetchedAssetDetails);
  const currencyRates = useSelector((state) => state.currencyRates);

  useEffect(() => {
    setLoader(true);

    Promise.allSettled([
      dispatch(getCurrencyRates('USD')),
      dispatch(getCryptoAssetsDetail({ filter })),
    ]).then(() => {
      setLoader(false);
    });
  }, [filter]);

  useEffect(() => {
    if (fetchedAssetDetails) {
      dispatch(updateTransactionState());
    }
  }, [dispatch, fetchedAssetDetails]);

  const getRateInUsdt = (coin_symbol, amount) => {
    if (currencyRates && Object.keys(currencyRates).length) {
      const total_in_usdt = parseFloat(
        parseFloat(1 / currencyRates[coin_symbol === 'USDT' ? 'USD' : coin_symbol])
          * parseFloat(amount),
      );

      // eslint-disable-next-line no-restricted-globals
      if (!isNaN(total_in_usdt)) {
        return total_in_usdt;
      }
      return null;
    }
    return '-';
  };

  return (
    loader ? (
      <FullPageTransparentLoader />
    ) : (
      <div style={{
        display: 'flex', gap: '24px', marginBottom: '20px', overflowX: 'auto', paddingBottom: '10px',
      }}
      >
        {transactionAssets?.length > 0 && (
          transactionAssets?.map((item) => (
            item.currency && (
              <Link
                to={item?.isFiat ? '/fiat-currency' : '/currency'}
                style={{
                  minWidth: '387px',
                  minHeight: '380px',
                  backgroundColor: '#0f1323',
                  color: 'white',
                  padding: '28px',
                  paddingTop: '30px',
                  paddingBottom: '30px',
                  borderRadius: '12px',
                  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                }}
                key={item?.currencyId}
              >
                <h5 style={{ fontWeight: 600, marginBottom: '10px', fontSize: '28px' }}>{item?.currencyName}</h5>
                <CryptoPieChart item={item} />
                <h5 style={{ fontWeight: 600 }}>Total transactions</h5>
                <p style={{ color: '#11CABE', fontSize: '20px' }}>
                  {`$ ${getRateInUsdt(item?.currency, item?.totalAmount)?.toFixed(2)} USD`}
                </p>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between', marginTop: '55px' }}
                >
                  <img
                    src={GetCoinImg(item?.currency)}
                    alt={item?.currency}
                    style={{ width: '50px', height: '50px' }}
                  />
                  <div>
                    <div style={{ fontSize: '16px' }}>{item?.currencyName}</div>
                    <h5 style={{ fontWeight: 700 }}>
                      USD
                      {' '}
                      {getRateInUsdt(item?.currency, 1)?.toFixed(4)}
                    </h5>
                  </div>
                  <p style={{ color: 'grey' }}>{item?.currency}</p>
                </div>
                {/* <CanvasGraph currency={item?.currency} /> */}
              </Link>
            )
          ))
        )}
      </div>
    )
  );
}

export default CryptoCharts;
