import {
  faPencilAlt,
  faUndo,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ModalFilters } from '../../components';
import { ModalContext } from '../../context';
import { toFixed } from '../../helpers/helper';
import { EditOrderModal } from '../../pages/OrdersHistory/EditOrderModal';
import { omitColumnsNotFromConfig } from '../helper';

import './ComposedOrdersSchema.css';

const formatNumber = (num, maxBeforeDecimal, maxAfterDecimal) => {
  const [before, after] = num.toString().split('.');
  const formattedBefore = Number(before).toLocaleString('en-US', { maximumFractionDigits: 0 });
  const paddedBefore = formattedBefore.padStart(maxBeforeDecimal, ' ');
  const paddedAfter = (after || '').padEnd(maxAfterDecimal, '0');
  return `${paddedBefore}.${paddedAfter}`;
};

export const ComposedOrdersSchema = (
  columnConfig,
  filters,
  setFilters,
  permissionNames,
  handleRevertTradeHistory,
  tradingTypeFilter,
  setTradingTypeFilter,
  tradingPairFilter,
  setTradingPairFilter,
  openedByFilter,
  setOpenedByFilter,
  directionFilter,
  setDirectionFilter,
  statusFilter,
  setStatusFilter,
  pairNames,
  statusData,
  page, 
  limit, 
) => {
  const { showModal, hideModal } = useContext(ModalContext);
  const isUserCanSeeClientCard = permissionNames.includes('clients');
  const isUserCanSeeLeadsCard = permissionNames.includes('leads');

  const columns = [
    {
      name: 'Order ID',
      selector: (row) => row._id,
      sortable: true,
      cell: (row) => (
        <span className="order-id-cell">
          {row._id}
        </span>
      ),
      minWidth: '200px',
      omit: omitColumnsNotFromConfig(columnConfig, 'Order ID'),
    },
    {
      name: 'User',
      selector: (row) => row.user,
      sortable: true,
      cell: (row) => {
        // const canViewUserDetails = (row.user.clientType === 1 && isUserCanSeeLeadsCard) || (row.user.clientType === 2 && isUserCanSeeClientCard);
        const canViewUserDetails = true;

        if (canViewUserDetails) {
          return (
            <Link to={`/user-detail/${row.user._id}`} className="text-decoration-none">
              {`${row.user.firstName} ${row.user.lastName}`}
            </Link>
          );
        }

        return `${row.user.firstName} ${row.user.lastName}`;
      },
      minWidth: '200px',
      omit: omitColumnsNotFromConfig(columnConfig, 'User'),
    },
    {
      name: (
        <ModalFilters
          data={[{ name: 'Futures', _id: 'Futures' }, { name: 'Spot', _id: 'Spot' }]}
          filters={tradingTypeFilter}
          setFilters={setTradingTypeFilter}
          tabName="Trading Type"
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={filters}
          setUserFilters={setFilters}
        />
      ),
      cell: (row) => (
        <span className={`badge ${row.tradingType === 'Futures' ? 'badge-futures' : 'badge-spot'}`}>
          {row.tradingType}
        </span>
      ),
      minWidth: '200px',
      omit: omitColumnsNotFromConfig(columnConfig, 'Trading Type'),
    },
    {
      name: (
        <ModalFilters
          data={[{ name: 'Limit', _id: 'Limit' }, { name: 'Market', _id: 'Market' }]}
          filters={openedByFilter}
          setFilters={setOpenedByFilter}
          tabName="Opened By" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={filters}
          setUserFilters={setFilters}
        />
      ),
      selector: (row) => row.openOrderType,
      minWidth: '200px',
      omit: omitColumnsNotFromConfig(columnConfig, 'Opened By'),
    },
    {
      name: (
        <ModalFilters
          data={pairNames}
          filters={tradingPairFilter}
          setFilters={setTradingPairFilter}
          tabName="Trading Pair" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={filters}
          setUserFilters={setFilters}
        />
      ),
      selector: (row) => row.tradingPair,
      minWidth: '200px',
      omit: omitColumnsNotFromConfig(columnConfig, 'Trading Pair'),
    },
    {
      name: (
        <ModalFilters
          data={[{ name: 'Buy', _id: 'Buy' }, { name: 'Sell', _id: 'Sell' }]}
          filters={directionFilter}
          setFilters={setDirectionFilter}
          tabName="Direction" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={filters}
          setUserFilters={setFilters}
        />
      ),
      selector: (row) => row.direction,
      cell: (row) => (
        <span className={`badge ${row.direction === 'Buy' ? 'badge-buy' : 'badge-sell'}`}>
          {row.direction}
        </span>
      ),
      minWidth: '200px',
      omit: omitColumnsNotFromConfig(columnConfig, 'Direction'),
    },
    {
      name: 'Swap',
      selector: (row) => row.swap,
      sortable: true,
      cell: (row) => {
        if (row.swap === 'N/A') {
          return <span className="number-cell">N/A</span>;
        }

        const currencySymbol = row.tradingType === 'Spot' && row.direction === 'Sell' 
          ? row.toCurrency.symbol 
          : row.fromCurrency.symbol;

        return <span className="number-cell">{`${formatNumber(toFixed(row.swap, 8), 12, 8)} ${currencySymbol}`}</span>;
      },
      minWidth: '200px',
      omit: omitColumnsNotFromConfig(columnConfig, 'Swap'),
    },
    {
      name: 'Commission',
      selector: (row) => row.commission,
      sortable: true,
      cell: (row) => {
        if (row.commission === 'N/A') {
          return <span className="number-cell">N/A</span>;
        }

        return <span className="number-cell">{formatNumber(toFixed(row.commission, 2), 8, 2)}</span>;
      },
      minWidth: '200px',
      omit: omitColumnsNotFromConfig(columnConfig, 'Commission'),
    },
    {
      name: 'Leverage',
      selector: (row) => row.leverage,
      sortable: true,
      cell: (row) => {
        if (row.leverage === 'N/A') {
          return <span className="number-cell">N/A</span>;
        }

        return <span className="number-cell">{`${toFixed(row.leverage, 0)}x`}</span>;
      },
      minWidth: '200px',
      omit: omitColumnsNotFromConfig(columnConfig, 'Leverage'),
    },
    {
      name: 'Order Qty',
      selector: (row) => row.orderQty,
      sortable: true,
      cell: (row) => {
        if (row.orderQty === 'N/A') {
          return <span className="number-cell">N/A</span>;
        }

        const currencySymbol = row.tradingType === 'Spot' && row.direction === 'Sell' 
          ? row.fromCurrency.symbol 
          : row.toCurrency.symbol;
        const formattedOrderQty = formatNumber(toFixed(row.orderQty, 8), 12, 8);

        return <span className="number-cell">{`${formattedOrderQty} ${currencySymbol}`}</span>;
      },
      minWidth: '200px',
      omit: omitColumnsNotFromConfig(columnConfig, 'Order Qty'),
    },
    {
      name: 'Open Price',
      selector: (row) => row.tradeStartPrice,
      sortable: true,
      cell: (row) => {
        if (row.tradeStartPrice === 'N/A') {
          return <span className="number-cell">N/A</span>;
        }

        const currencySymbol = row.tradingType === 'Spot' && row.direction === 'Sell' 
          ? row.toCurrency.symbol 
          : row.fromCurrency.symbol;
        const formattedOpenPrice = formatNumber(toFixed(row.tradeStartPrice, 8), 12, 8);

        return <span className="number-cell">{`${formattedOpenPrice} ${currencySymbol}`}</span>;
      },
      minWidth: '200px',
      omit: omitColumnsNotFromConfig(columnConfig, 'Open Price'),
    },
    {
      name: 'Close Price',
      selector: (row) => row.exitPrice,
      sortable: true,
      cell: (row) => {
        if (row.exitPrice === 'N/A') {
          return <span className="number-cell">N/A</span>;
        }

        const currencySymbol = row.tradingType === 'Spot' && row.direction === 'Sell' 
          ? row.toCurrency.symbol 
          : row.fromCurrency.symbol;
        const formattedClosePrice = formatNumber(toFixed(row.exitPrice, 8), 12, 8);

        return <span className="number-cell">{`${formattedClosePrice} ${currencySymbol}`}</span>;
      },
      minWidth: '200px',
      omit: omitColumnsNotFromConfig(columnConfig, 'Close Price'),
    },
    {
      name: 'Order Volume',
      selector: (row) => row.orderVolume,
      sortable: true,
      cell: (row) => {
        if (row.orderVolume === 'N/A') {
          return <span className="number-cell">N/A</span>;
        }

        const currencySymbol = row.tradingType === 'Spot' && row.direction === 'Sell' 
          ? row.toCurrency.symbol 
          : row.fromCurrency.symbol;
        const formattedOrderVolume = formatNumber(toFixed(row.orderVolume, 8), 12, 8);

        return <span className="number-cell">{`${formattedOrderVolume} ${currencySymbol}`}</span>;
      },
      minWidth: '350px',
      omit: omitColumnsNotFromConfig(columnConfig, 'Order Volume'),
    },
    {
      name: 'Profit/Loss',
      selector: (row) => row.profitLoss,
      sortable: true,
      cell: (row) => {
        if (row.profitLoss === 'N/A') {
          return <span className="number-cell">N/A</span>;
        }

        const currencySymbol = row.tradingType === 'Spot' && row.direction === 'Sell' 
          ? row.toCurrency.symbol 
          : row.fromCurrency.symbol;
        const formattedProfitLoss = formatNumber(toFixed(row.profitLoss, 8), 12, 8);

        return (
          <span className={`number-cell ${parseFloat(row.profitLoss) >= 0 ? 'text-success' : 'text-danger'}`}>
            {`${formattedProfitLoss} ${currencySymbol}`}
          </span>
        );
      },
      minWidth: '200px',
      omit: omitColumnsNotFromConfig(columnConfig, 'Profit/Loss'),
    },
    {
      name: 'Start Time',
      selector: (row) => row.startTime,
      sortable: true,
      cell: (row) => moment(row.startTime).format('YYYY-MM-DD HH:mm:ss'),
      minWidth: '200px',
      omit: omitColumnsNotFromConfig(columnConfig, 'Start Time'),
    },
    {
      name: 'End Time',
      selector: (row) => row.endTime,
      sortable: true,
      cell: (row) => moment(row.endTime).format('YYYY-MM-DD HH:mm:ss'),
      minWidth: '200px',
      omit: omitColumnsNotFromConfig(columnConfig, 'End Time'),
    },
    {
      name: (
        <ModalFilters
          data={statusData}
          filters={statusFilter}
          setFilters={setStatusFilter}
          tabName="Status" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={filters}
          setUserFilters={setFilters}
        />
      ),
      selector: (row) => row.status,
      cell: (row) => {
        const statusMap = {
          0: { text: 'Pending', class: 'badge-pending' },
          1: { text: 'Processing', class: 'badge-processing' },
          2: { text: 'Completed', class: 'badge-completed' },
          3: { text: 'Cancelled', class: 'badge-cancelled' },
          4: { text: 'Liquidated', class: 'badge-liquidated' },
        };
        const status = statusMap[row.status] || { text: 'Unknown', class: '' };
        return (
          <span className={`badge ${status.class}`}>
            {status.text}
          </span>
        );
      },
      minWidth: '200px',
      omit: omitColumnsNotFromConfig(columnConfig, 'Status'),
    },
    {
      name: 'Actions',
      cell: (row) => {
        // const isUserCanEditOrder = permissionName.includes('edit_order');
        // const isUserCanRevertOrder = permissionName.includes('revert_order');
        const isUserCanEditOrder = true;
        const isUserCanRevertOrder = true;

        if (row.tradingType === 'Futures' && (isUserCanEditOrder || isUserCanRevertOrder)) {
          return (
            <div className="actions-container">
              {isUserCanEditOrder && (
              <button
                type="button"
                className="btn btn-success btn-sm rounded-pill me-2"
                onClick={() => {
                  showModal({
                    headerContent: <h3>Edit Order</h3>,
                    bodyContent: <EditOrderModal 
                      order={row.orderInfo} 
                      hideModal={hideModal}
                      filters={filters}
                      page={page}
                      limit={limit}
                    />,
                  });
                }}
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </button>
              )}
              {isUserCanRevertOrder && (
              <button
                type="button"
                className="btn btn-danger btn-sm rounded-pill"
                onClick={(e) => handleRevertTradeHistory(e, row)}
              >
                <FontAwesomeIcon icon={faUndo} />
              </button>
              )}
            </div>
          );
        }

        return <span>-</span>;
      },
      minWidth: '200px',
      omit: omitColumnsNotFromConfig(columnConfig, 'Actions'),
    },
  ];

  return columns;
};
