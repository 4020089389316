import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClone } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ModalFilters } from '../../components';
import { omitColumn, formatDate } from '../helper';

import { RangeModalFilter } from '../../pages/Transactions/modalFilters';
import styles from './styles.module.css';

export const SpotOrdersSchema = (
  filters,
  setFilters,
  pairNames,
  spotPairsFilter,
  setSpotPairsFilter,
  typeFilter,
  setTypeFilter,
  directionFilter,
  setDirectionFilter,
  amountRangeFilter,
  setAmountRangeFilter,
  setDebounceAmountRange,
  permissionName,
  columnConfig,
) => {
  const copyReferral = () => {
    toast.success('Successfully copied!', {
      autoClose: 1000,
    });
  };

  const isUserCanSeeClientCard = permissionName.includes('clients');
  const isUserCanSeeLeadsCard = permissionName.includes('leads');

  const spotOrders = [
    {
      name: (
        <div className={`d-flex align-items-start flex-column search-input-des p-2 pl-0 ${styles.inputsWrapper}`}>
          Order Id
        </div>
      ),
      selector: (row) => (
        <CopyToClipboard text={row?._id}>
          <span>
            {row?._id?.slice(0, 4)}
            ...
            {row?._id?.slice((row?._id.length || 0) - 4, row?._id.length)}
            <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="ms-2" />
          </span>
        </CopyToClipboard>
      ),
      omit: omitColumn(columnConfig, 'Order ID'),
      width: '200px',
    },
    {
      name: (
        <div className={`d-flex align-items-start flex-column search-input-des p-2 pl-0 ${styles.inputsWrapper}`}>
          User Name
        </div>
      ),
      selector: (row) => (
        (row.user.clientType === 1 && isUserCanSeeLeadsCard) || (row.user.clientType === 2 && isUserCanSeeClientCard) ? (
          <Link to={`/user-detail/${row.user._id}`} className="text-decoration-none">
            {`${row.user.firstName} ${row.user.lastName}`}
          </Link>
        ) : `${row.user.firstName} ${row.user.lastName}`
      ),
      omit: omitColumn(columnConfig, 'User Name'),
      width: '200px',
    },
    {
      name: (
        <ModalFilters
          data={[{ name: 'Market', _id: '1' }, { name: 'Limit', _id: '0' }]}
          filters={typeFilter}
          setFilters={setTypeFilter}
          tabName="Type" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={filters}
          setUserFilters={setFilters}
        />
      ),
      cell: (row) => (row?.marketOrder ? 'Market' : 'Limit'),
      omit: omitColumn(columnConfig, 'Type'),
      width: '200px',
    },
    {
      name: (
        <ModalFilters
          data={pairNames}
          filters={spotPairsFilter}
          setFilters={setSpotPairsFilter}
          tabName="Spot Pair" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={filters}
          setUserFilters={setFilters}
        />
      ),
      cell: (row) => (row?.spotPair),
      omit: omitColumn(columnConfig, 'Spot Pair'),
      width: '200px',
    },
    {
      name: (
        <ModalFilters
          data={[{ name: 'Buy', _id: '1' }, { name: 'Sell', _id: '0' }]}
          filters={directionFilter}
          setFilters={setDirectionFilter}
          tabName="Direction" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={filters}
          setUserFilters={setFilters}
        />
      ),
      cell: (row) => (row?.tradeType ? 'Buy' : 'Sell'),
      omit: omitColumn(columnConfig, 'Direction'),
      width: '150px',
    },
    {
      name: (
        <RangeModalFilter
          columnName="Order Value"
          value={amountRangeFilter ?? [0, 100000000]} 
          setValue={setAmountRangeFilter}
          filters={{}}
          setFilters={setDebounceAmountRange}
          filedName="amountRange"
          debounceAmountRange
          maxValue={100000000}
          minValue={0}
        />
      ),
      selector: (row) => {
        const investedQty = parseFloat(row?.investedQty);
        const tradeStartPrice = parseFloat(row?.tradeStartPrice);
        const orderValue = parseFloat(investedQty * tradeStartPrice).toFixed(2);
        const currency = row?.tradeType ? row.fromCurrency.symbol : row.toCurrency.symbol;
        return `${orderValue} ${currency}`;
      },
      omit: omitColumn(columnConfig, 'Order Value'),
      width: '200px',
    },
    {
      name: 'Filled Qty',
      selector: (row) => {
        const isCanceled = row?.status === 3;
        const investedQty = parseFloat(row?.investedQty).toFixed(2);
        const filledQty = !isCanceled ? investedQty : 0;
        const currency = row?.tradeType ? row.toCurrency.symbol : row.fromCurrency.symbol;
        return `${filledQty} ${currency}`;
      },
      omit: omitColumn(columnConfig, 'Filled Qty'),
      width: '200px',
    },
    {
      name: 'Order Price',
      cell: (row) => `${row?.tradeStartPrice} ${row.toCurrency.symbol}`,
      omit: omitColumn(columnConfig, 'Order Price'),
      width: '200px',
    },
    {
      name: 'Order Qty',
      selector: (row) => {
        const orderQty = parseFloat(row?.investedQty).toFixed(2);
        const currency = row?.tradeType ? row.toCurrency.symbol : row.fromCurrency.symbol;
        return `${orderQty} ${currency}`;
      },
      omit: omitColumn(columnConfig, 'Order Qty'),
    },
    {
      name: 'Status',
      selector: (row) => (row?.status === 2 ? 'Completed' : 'Cancelled'),
      omit: omitColumn(columnConfig, 'Status'),
    },
    {
      name: 'Time Opened',
      omit: omitColumn(columnConfig, 'Time Opened'),
      minWidth: '200px',
      cell: ({ createdAt }) => (<span>{formatDate(new Date(createdAt))}</span>),
    },
  ];

  return spotOrders;
};
