import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { ModalContext } from '../../context';
import { createUserCommission } from '../../redux/UsersCommissions/usersCommissionsActions';

export function AddUsersCommissionsModal({ userId }) {
  const dispatch = useDispatch();
  const { hideModal } = useContext(ModalContext);

  const { pairs } = useSelector((state) => state);
  const [selectedPair, setSelectedPair] = useState({});
  const [commission, setCommission] = useState('0');

  const handleSave = () => {
    dispatch(createUserCommission(userId, { commission, pairId: selectedPair._id }));
    hideModal();
  };

  return (
    <>
      <div className="row mb-3">
        <div className="form-group mt-2">
          <label className="control-label">Pair</label>
          <Dropdown className="t-settings__dropdown">
            <Dropdown.Toggle className="t-settings_dropdown-btn" variant="">
              {Object.keys(selectedPair).length ? selectedPair.name : 'Select'}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {pairs.map(({ _id, name }) => (
                <Dropdown.Item
                  key={_id}
                  onClick={() => {
                    setSelectedPair({ _id, name });
                  }}
                >
                  {name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div className="form-group mt-2">
          <label className="control-label">Commission</label>
          <input
            type="number"
            className="form-control"
            placeholder="Enter Commission"
            name="name"
            value={commission}
            onChange={({ target }) => {
              setCommission(target.value.replace(/e/gi, ''));
            }}
          />
        </div>
      </div>
      <div>
        <button
          type="button"
          className="btn btn-default me-2"
          onClick={handleSave}
        >
          Save
        </button>
        <button
          type="button"
          className="btn btn-danger"
          onClick={hideModal}
        >
          Cancel
        </button>
      </div>
    </>
  );
}
